import { dateFormat } from "../../shared/util/dateFormat";
import { compareDates } from "../../shared/util/compareDates";
import { GeneralUserData, KanbanFilledData } from "../../shared/data/types";

export const setWeekActivityDataHomeUser = (
  loadedData: GeneralUserData,
  pastDatesMultiplier: number = 0
): {
  date: string;
  generatedProposals: number;
  registeredCustomers: number;
  registeredKits: number;
  closedSales: number;
}[] => {
  return [
    {
      date: dateFormat(-6 - pastDatesMultiplier * 7)?.substr(0, 5),
      generatedProposals:
        loadedData?.proposals?.filter((proposal) => {
          return proposal?.registryDate?.includes(
            dateFormat(-6 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredCustomers:
        loadedData?.customers?.filter((customer) => {
          return customer?.registryDate?.includes(
            dateFormat(-6 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredKits:
        loadedData?.kits?.filter((kit) => {
          return kit?.registryDate?.includes(
            dateFormat(-6 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      closedSales:
        loadedData?.sales?.filter((s) => {
          return s?.saleClosedDate?.includes(
            dateFormat(-6 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
    },
    {
      date: dateFormat(-5 - pastDatesMultiplier * 7)?.substr(0, 5),
      generatedProposals:
        loadedData?.proposals?.filter((proposal) => {
          return proposal?.registryDate?.includes(
            dateFormat(-5 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredCustomers:
        loadedData?.customers?.filter((customer) => {
          return customer?.registryDate?.includes(
            dateFormat(-5 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredKits:
        loadedData?.kits?.filter((kit) => {
          return kit?.registryDate?.includes(
            dateFormat(-5 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      closedSales:
        loadedData?.sales?.filter((s) => {
          return s?.saleClosedDate?.includes(
            dateFormat(-5 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
    },
    {
      date: dateFormat(-4 - pastDatesMultiplier * 7)?.substr(0, 5),
      generatedProposals:
        loadedData?.proposals?.filter((proposal) => {
          return proposal?.registryDate?.includes(
            dateFormat(-4 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredCustomers:
        loadedData?.customers?.filter((customer) => {
          return customer?.registryDate?.includes(
            dateFormat(-4 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredKits:
        loadedData?.kits?.filter((kit) => {
          return kit?.registryDate?.includes(
            dateFormat(-4 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      closedSales:
        loadedData?.sales?.filter((s) => {
          return s?.saleClosedDate?.includes(
            dateFormat(-4 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
    },
    {
      date: dateFormat(-3 - pastDatesMultiplier * 7)?.substr(0, 5),
      generatedProposals:
        loadedData?.proposals?.filter((proposal) => {
          return proposal?.registryDate?.includes(
            dateFormat(-3 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredCustomers:
        loadedData?.customers?.filter((customer) => {
          return customer?.registryDate?.includes(
            dateFormat(-3 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredKits:
        loadedData?.kits?.filter((kit) => {
          return kit?.registryDate?.includes(
            dateFormat(-3 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      closedSales:
        loadedData?.sales?.filter((s) => {
          return s?.saleClosedDate?.includes(
            dateFormat(-3 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
    },
    {
      date: dateFormat(-2 - pastDatesMultiplier * 7)?.substr(0, 5),
      generatedProposals:
        loadedData?.proposals?.filter((proposal) => {
          return proposal?.registryDate?.includes(
            dateFormat(-2 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredCustomers:
        loadedData?.customers?.filter((customer) => {
          return customer?.registryDate?.includes(
            dateFormat(-2 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredKits:
        loadedData?.kits?.filter((kit) => {
          return kit?.registryDate?.includes(
            dateFormat(-2 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      closedSales:
        loadedData?.sales?.filter((s) => {
          return s?.saleClosedDate?.includes(
            dateFormat(-2 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
    },
    {
      date: dateFormat(-1 - pastDatesMultiplier * 7)?.substr(0, 5),
      generatedProposals:
        loadedData?.proposals?.filter((proposal) => {
          return proposal?.registryDate?.includes(
            dateFormat(-1 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredCustomers:
        loadedData?.customers?.filter((customer) => {
          return customer?.registryDate?.includes(
            dateFormat(-1 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredKits:
        loadedData?.kits?.filter((kit) => {
          return kit?.registryDate?.includes(
            dateFormat(-1 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      closedSales:
        loadedData?.sales?.filter((s) => {
          return s?.saleClosedDate?.includes(
            dateFormat(-1 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
    },
    {
      date: dateFormat(0 - pastDatesMultiplier * 7)?.substr(0, 5),
      generatedProposals:
        loadedData?.proposals?.filter((proposal) => {
          return proposal?.registryDate?.includes(
            dateFormat(0 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredCustomers:
        loadedData?.customers?.filter((customer) => {
          return customer?.registryDate?.includes(
            dateFormat(0 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      registeredKits:
        loadedData?.kits?.filter((kit) => {
          return kit?.registryDate?.includes(
            dateFormat(0 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
      closedSales:
        loadedData?.sales?.filter((s) => {
          return s?.saleClosedDate?.includes(
            dateFormat(0 - pastDatesMultiplier * 7)
          );
        })?.length || 0,
    },
  ];
};
