import { makeStyles } from "@material-ui/core/styles";

export const LoadingSpinnerOverlayRegularStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    zIndex: 5,
    width: "99%",
    height: "99%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    background: "rgba(255, 255, 255, 0.70)",
    top: 0,
    left: 0,
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  infoCard: {
    display: "flex",
    zIndex: 5,
    width: "99%",
    height: "99%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: "5px",
    background: "rgba(255, 255, 255, 0.70)",
    top: 0,
    left: 0,
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  image: {
    display: "flex",
    zIndex: 5,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    background: "rgba(255, 255, 255, 0.70)",
    top: 0,
    left: 0,
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));
