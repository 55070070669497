import React, { useState, Fragment } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";

import { InputCalendar } from "../../../shared/components/FormElements/InputCalendar";
import {
  processSelectedEarlierTimePeriodHandler,
  processSelecteOlderdTimePeriodHandler,
} from "../../../customer/util/filteringHandlers";
import { Card } from "../UIElements/Card";
import { AuthContextProps, TimePeriodData } from "../../data/types";

import "../UIElements/SearchBarGeneralStyles.scss";

interface FilterFetchTypeProps {
  auth: AuthContextProps;
  isLoading: boolean;
  fetchDataHandler: (props?: any) => void;
  fetchId: string;
  setFetchId: React.Dispatch<React.SetStateAction<string>>;
  filterValue?: TimePeriodData;
  setFilterValue?: React.Dispatch<React.SetStateAction<TimePeriodData>>;
  excludeAllDataOpt?: boolean;
  noMarginBottom?: boolean;
}

export const FilterFetchType = (props: FilterFetchTypeProps) => {
  const {
    isLoading,
    fetchDataHandler,
    auth,
    fetchId,
    setFetchId,
    excludeAllDataOpt = false,
    noMarginBottom,
    filterValue,
    setFilterValue,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const vendorsOptions =
    auth.vendors?.map((vendor) => {
      return {
        value: vendor._id,
        option: `${vendor.personalData?.name} - ${vendor.email}`,
      };
    }) || [];

  const allOptions = !excludeAllDataOpt
    ? [
        { value: auth.userId, option: "GERENTE" },
        ...vendorsOptions,
        { value: auth.managerId, option: "TODOS OS DADOS" },
      ]
    : [{ value: auth.userId, option: "GERENTE" }, ...vendorsOptions];

  const selectedEarlierTimePeriodHandler = (date: string) => {
    if (setFilterValue) {
      processSelectedEarlierTimePeriodHandler(date, setFilterValue);
    }
  };

  const selecteOlderdTimePeriodHandler = (date: string) => {
    if (setFilterValue) {
      processSelecteOlderdTimePeriodHandler(date, setFilterValue);
    }
  };

  const changeValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFetchId(e.target.value);
  };

  const filterHandler = () => {
    fetchDataHandler();
  };

  return (
    <div
      style={{
        paddingTop: "30px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
      className="container-100"
    >
      <Button
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        color="primary"
        variant="outlined"
      >
        {!isLoading ? (
          <FilterListRoundedIcon />
        ) : (
          <CircularProgress size={20} color="primary" />
        )}
        &nbsp;&nbsp; FILTROS
      </Button>
      <Popover
        id="filters-customers"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Card
          cardType={noMarginBottom ? "searchCardNoMarginBottom" : "searchCard"}
        >
          <FormControl>
            <InputLabel id="fetch-type-filter-selector-label">
              Filtrar por
            </InputLabel>
            <Select
              labelId="fetch-type-filter-selector-label"
              id="fetch-type-filter-selector"
              value={fetchId}
              onChange={changeValueHandler}
            >
              {allOptions.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {filterValue && setFilterValue && (
            <div className="searchbar-time-period-container">
              <InputCalendar
                id="time-period-start"
                label="Início período"
                format="dd/MM/yy"
                disableFuture
                initialvalue={filterValue.timePeriodEarlierSelected}
                selectedEarlierTimePeriodHandler={
                  selectedEarlierTimePeriodHandler
                }
              />
              <InputCalendar
                id="time-period-end"
                label="Final período"
                format="dd/MM/yy"
                disableFuture
                initialvalue={filterValue.timePeriodOlderSelected}
                selecteOlderdTimePeriodHandler={selecteOlderdTimePeriodHandler}
              />
            </div>
          )}
          {!isLoading && (
            <Button color="primary" variant="outlined" onClick={filterHandler}>
              FILTRAR
            </Button>
          )}
          {isLoading && (
            <div
              className="searchbar-loading-spinner"
              style={{ marginTop: "10px" }}
            >
              <CircularProgress color="primary" />
            </div>
          )}
        </Card>
      </Popover>
    </div>
  );
};
