import {
  FormHookDispState,
  SettingsContextProps,
  TariffData,
} from "../../shared/data/types";

export const calcSimValues = (
  formState: FormHookDispState,
  loadedTariffs: TariffData[]
) => {
  const chosenTariff = loadedTariffs.find((tariff) => {
    return (
      tariff.powerDistCompany === formState.inputs?.powerDistCompany?.value
    );
  });

  let kwhPrice = 0;
  let kwhPricePeak = 0;
  let avgConsumption = 0;
  let avgConsumptionPeak = 0;
  let demandPrice = 0;
  let demandPricePeak = 0;
  let bWire = 0;
  if (
    !!chosenTariff &&
    formState.inputs?.tariffModality?.value === "Grupo B (Baixa Tensão)"
  ) {
    kwhPrice =
      chosenTariff.bGroupTariffs?.b1?.teTaxed +
        chosenTariff.bGroupTariffs?.b1?.tusdTaxed || 0;
    avgConsumption = +formState.inputs?.energyBillPrice?.value / kwhPrice || 0;
    bWire = chosenTariff.bGroupTariffs?.b1?.bWirePerc * kwhPrice || 0;
  } else if (
    !!chosenTariff &&
    formState.inputs?.tariffModality?.value === "Grupo A (Alta Tensão)"
  ) {
    if (formState.inputs?.classification?.value === "Verde") {
      kwhPrice =
        chosenTariff.aGroupTariffs?.a4?.green?.nonPeak?.teTaxed +
          chosenTariff.aGroupTariffs?.a4?.green?.nonPeak?.tusdTaxed || 0;

      kwhPricePeak =
        chosenTariff.aGroupTariffs?.a4?.green?.peak?.teTaxed +
          chosenTariff.aGroupTariffs?.a4?.green?.peak?.tusdTaxed || 0;

      avgConsumption =
        +formState.inputs?.energyBillPrice?.value / kwhPrice || 0;
      avgConsumptionPeak =
        +formState.inputs?.energyBillPricePeak?.value / kwhPricePeak || 0;

      demandPrice =
        +formState.inputs?.demand?.value *
        chosenTariff.aGroupTariffs?.a4?.green?.nonPeak?.demandTaxed;
    } else if (formState.inputs?.classification?.value === "Azul") {
      kwhPrice =
        chosenTariff.aGroupTariffs?.a4?.blue?.nonPeak?.teTaxed +
          chosenTariff.aGroupTariffs?.a4?.blue?.nonPeak?.tusdTaxed || 0;

      kwhPricePeak =
        chosenTariff.aGroupTariffs?.a4?.blue?.peak?.teTaxed +
          chosenTariff.aGroupTariffs?.a4?.blue?.peak?.tusdTaxed || 0;

      avgConsumption =
        +formState.inputs?.energyBillPrice?.value / kwhPrice || 0;
      avgConsumptionPeak =
        +formState.inputs?.energyBillPricePeak?.value / kwhPricePeak || 0;

      demandPrice =
        +formState.inputs?.demand?.value *
        chosenTariff.aGroupTariffs?.a4?.blue?.nonPeak?.demandTaxed;
      demandPricePeak =
        +formState.inputs?.demandPeak?.value *
        chosenTariff.aGroupTariffs?.a4?.blue?.peak?.demandTaxed;
    }
  }

  return {
    kwhPrice,
    kwhPricePeak,
    avgConsumption,
    avgConsumptionPeak,
    demandPrice,
    demandPricePeak,
    bWire,
  };
};
