import React, { useEffect, useState } from "react";
import MobileDrawer from "@material-ui/core/Drawer";

import { MobileNavLinks, MobileNavLinksProps } from "./MobileNavLinks";
import { BeforeInstallPromptEvent } from "../../../App";

interface SideDrawerProps {
  anchor: "left" | "top" | "right" | "bottom";
  open: boolean;
  onClose: () => void;
  classes: string;
  clickCloser: () => void;
  sideMenu?: boolean;
  addToHomescreenEvent: BeforeInstallPromptEvent;
  setAddToHomescreenEvent: React.Dispatch<
    React.SetStateAction<BeforeInstallPromptEvent>
  >;
  slug: string;
}

export const SideDrawer = (props: SideDrawerProps) => {
  const {
    addToHomescreenEvent,
    setAddToHomescreenEvent,
    anchor,
    open,
    onClose,
    classes,
    clickCloser,
    slug,
  } = props;

  return (
    <MobileDrawer anchor={anchor} open={open} onClose={onClose}>
      <nav className={classes}>
        <MobileNavLinks
          clickCloser={clickCloser}
          addToHomescreenEvent={addToHomescreenEvent}
          setAddToHomescreenEvent={setAddToHomescreenEvent}
          slug={slug}
        />
      </nav>
    </MobileDrawer>
  );
};
