import { makeStyles } from "@material-ui/core/styles";

export const PopoverMoveItemStyles = makeStyles(() => ({
  item: {
    width: "80px",
  },
  itemBigger: {
    width: "120px",
  },
}));
