import PersonAddDisabledRoundedIcon from "@material-ui/icons/PersonAddDisabledRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import ViewListRoundedIcon from "@material-ui/icons/ViewListRounded";
import PollRoundedIcon from "@material-ui/icons/PollRounded";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import PersonOutlineRoundedIcon from "@material-ui/icons/PersonOutlineRounded";
import MoneyOffRoundedIcon from "@material-ui/icons/MoneyOffRounded";
import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";
import TrendingDownRoundedIcon from "@material-ui/icons/TrendingDownRounded";
import LocalAtmRoundedIcon from "@material-ui/icons/LocalAtmRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSolarPanel } from "@fortawesome/free-solid-svg-icons";

import "./AvatarStyles.css";

interface AvatarProps {
  square?: boolean;
  large?: boolean;
  elementFlow?: boolean;
  preview?: boolean;
  elevated?: boolean;
  veryElevated?: boolean;
  logo?: boolean;
  userLogo?: string;
  placeholder?: boolean;
  customer?: boolean;
  proposal?: boolean;
  admin?: boolean;
  kit?: boolean;
  budget?: boolean;
  unsureBudget?: boolean;
  cancelUser?: boolean;
  trialUser?: boolean;
  graphUp?: boolean;
  graphDown?: boolean;
  money?: boolean;
  imgUrl?: string;
  background: string;
}

export const Avatar = (props: AvatarProps) => {
  const {
    square,
    large,
    elementFlow,
    preview,
    elevated,
    veryElevated,
    logo,
    userLogo,
    placeholder,
    customer,
    proposal,
    admin,
    kit,
    budget,
    cancelUser,
    trialUser,
    graphUp,
    graphDown,
    money,
    unsureBudget,
    background,
    imgUrl,
  } = props;

  return (
    <div
      className={`avatar ${square && "avatar-square"} ${
        large && "avatar-large"
      } ${elementFlow && "avatar-elementFlow"} ${preview && "avatar-preview"} ${
        elevated && "avatar-elevated"
      }
      ${veryElevated && "avatar-very-elevated"}`}
      style={{ background: `${background}` }}
    >
      {logo && (
        <img
          src={
            !!userLogo
              ? `${process.env.REACT_APP_ASSET_URL}/${userLogo}`
              : `${process.env.PUBLIC_URL}/logo-vazio.webp`
          }
          alt="logo"
        />
      )}
      {imgUrl && <img src={imgUrl} alt="Imagem" />}
      {placeholder && (
        <img
          src={
            !!userLogo
              ? `${process.env.REACT_APP_ASSET_URL}/${userLogo}`
              : `${process.env.PUBLIC_URL}/no-logo-opt-placeholder.webp`
          }
          alt="logo"
        />
      )}
      {customer && (
        <span>
          <PersonRoundedIcon />
        </span>
      )}
      {proposal && (
        <span>
          <ViewListRoundedIcon />
        </span>
      )}
      {admin && (
        <span>
          <PollRoundedIcon />
        </span>
      )}
      {kit && (
        <span>
          <FontAwesomeIcon style={{ fontSize: "2.4rem" }} icon={faSolarPanel} />
        </span>
      )}
      {budget && (
        <span>
          <AttachMoneyRoundedIcon />
        </span>
      )}
      {cancelUser && (
        <span>
          <PersonAddDisabledRoundedIcon />
        </span>
      )}
      {trialUser && (
        <span>
          <PersonOutlineRoundedIcon />
        </span>
      )}
      {unsureBudget && (
        <span>
          <MoneyOffRoundedIcon />
        </span>
      )}
      {graphUp && (
        <span>
          <TrendingUpRoundedIcon />
        </span>
      )}
      {graphDown && (
        <span>
          <TrendingDownRoundedIcon />
        </span>
      )}
      {money && (
        <span>
          <LocalAtmRoundedIcon />
        </span>
      )}
    </div>
  );
};
