import CircularProgress from "@material-ui/core/CircularProgress";

import { LoadingSpinnerOverlayRegularStyles } from "./LoadingSpinnerOverlayRegularStyles";

interface LoadingSpinnerOverlayRegularProps {
  image?: boolean;
  infoCard?: boolean;
  color?: "inherit" | "primary" | "secondary";
  style?: {};
}

export const LoadingSpinnerOverlayRegular = (
  props: LoadingSpinnerOverlayRegularProps
) => {
  const { color, image, infoCard, style } = props;
  const classes = LoadingSpinnerOverlayRegularStyles();

  if (image) {
    return (
      <div style={style} className={classes.image}>
        <CircularProgress color={color} />
      </div>
    );
  }

  if (infoCard) {
    return (
      <div style={style} className={classes.infoCard}>
        <CircularProgress color={color} />
      </div>
    );
  }

  return (
    <div style={style} className={classes.root}>
      <CircularProgress color={color} />
    </div>
  );
};
