import Popover from "@material-ui/core/Popover";
import React, { Fragment, useState } from "react";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

import { ButtonOrangeDefault } from "../../../shared/components/MUIThemed/ButtonOrangeDefault";

import { PopoverMoveItemStyles } from "../../../sales/components/PopoverMoveItemStyles";

interface PopoverChangeDatesProps {
  id: string;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement>>;
  disabled: boolean;
  setPastDatesMultiplier: React.Dispatch<React.SetStateAction<number>>;
  maxOffset?: number;
}

export const PopoverChangeDates = (props: PopoverChangeDatesProps) => {
  const {
    id,
    anchorEl,
    setAnchorEl,
    disabled,
    setPastDatesMultiplier,
    maxOffset = 3,
  } = props;
  const [offset, setOffset] = useState<number>(0);
  const open = Boolean(anchorEl);
  const classes = PopoverMoveItemStyles();

  return (
    <Fragment>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ButtonOrangeDefault
          className={classes.item}
          disabled={offset >= maxOffset}
          onClick={() => {
            if (offset < maxOffset) {
              setPastDatesMultiplier((prevValue) => {
                return prevValue + 1;
              });
              setOffset((prevValue) => {
                return prevValue + 1;
              });
            }
          }}
          color="primary"
        >
          <ArrowBackIosRoundedIcon />
        </ButtonOrangeDefault>
        <ButtonOrangeDefault
          disabled={disabled}
          className={classes.item}
          onClick={() => {
            setPastDatesMultiplier((prevValue) => {
              return prevValue - 1;
            });
            setOffset((prevValue) => {
              return prevValue - 1;
            });
          }}
          color="primary"
        >
          <ArrowForwardIosRoundedIcon />
        </ButtonOrangeDefault>
      </Popover>
    </Fragment>
  );
};
