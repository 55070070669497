import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { WarningBar } from "../../../shared/components/Navigation/WarningBar";
import { HomeCarousel } from "../../components/authenticated/HomeCarousel";
import { HomeUserSection0CardsData } from "../../content/HomeUserSection0CardsData";
import { HomeUserSection1GraphPeriodic } from "../../content/HomeUserSection1GraphPeriodic";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { FilterFetchType } from "../../../shared/components/Filters/FilterFetchType";
import { ModalNotification } from "../../../shared/components/UIElements/ModalNotification";
import { AuthContext } from "../../../shared/context/authContext";
import { SettingsContext } from "../../../shared/context/settingsContext";
import { useHttpClient } from "../../../shared/hooks/httpHook";
import { LoadingSpinnerFullScreenGraph } from "../../../shared/components/UIElements/LoadingSpinnerFullScreenGraph";
import { useIdbStorage } from "../../../shared/util/idbStorage";
import { getContent } from "../../api/contentAPI";
import {
  fetchGeneralUserData,
  fetchGeneralUserDataFromIDB,
  getUserSettings,
  getUserSettingsFromIDB,
} from "../../api/homePageUserAPI";
import { BeforeInstallPromptEvent } from "../../../App";
import { GeneralUserData, ContentData } from "../../../shared/data/types";

import { HomeStyles } from "./HomeStyles";

interface HomeProps {
  addToHomescreenEvent: BeforeInstallPromptEvent;
  setAddToHomescreenEvent: React.Dispatch<
    React.SetStateAction<BeforeInstallPromptEvent>
  >;
  needCriticalUpdate: boolean;
  needUpdate: boolean;
}

export default function HomeUser(props: HomeProps) {
  const {
    addToHomescreenEvent,
    setAddToHomescreenEvent,
    needCriticalUpdate,
    needUpdate,
  } = props;
  const auth = useContext(AuthContext);
  const settingsCtx = useContext(SettingsContext);
  const history = useHistory();
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const { openIndexedDBHandlerComp, readAllDataInIdb } = useIdbStorage();
  const classes = HomeStyles();
  const [loadedContent, setLoadedContent] = useState<ContentData>(null);
  const [loadedData, setLoadedData] = useState<GeneralUserData>(null);
  const [fetchId, setFetchId] = useState<string>(auth?.userId || "");
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  const [firstAcess, setFirstAcess] = useState<boolean>(
    !!JSON.parse(localStorage.getItem("first-access"))
      ? JSON.parse(localStorage.getItem("first-access")).firstAcess
      : true
  );
  const [networkData1Received, setNetworkData1Received] =
    useState<boolean>(false);

  useEffect(() => {
    if (auth.isLoggedIn) {
      getUserSettings({ sendRequest, settingsCtx, auth, setSettingsLoaded });
    }
  }, [sendRequest, auth.isLoggedIn]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      getContent({ sendRequest, setLoadedContent });
    }
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if ("indexedDB" in window) {
      getUserSettingsFromIDB({
        networkDataReceived: settingsLoaded,
        openIndexedDBHandlerComp,
        readAllDataInIdb,
        setLoadedData: setSettingsLoaded,
      });
    }
  }, [settingsLoaded, openIndexedDBHandlerComp, readAllDataInIdb]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      fetchGeneralUserData({
        sendRequest,
        auth,
        setNetworkDataReceived: setNetworkData1Received,
        setLoadedData,
      });
      setFetchId(auth.userId);
    }
  }, [sendRequest, auth.isLoggedIn]);

  useEffect(() => {
    if ("indexedDB" in window) {
      fetchGeneralUserDataFromIDB({
        networkDataReceived: networkData1Received,
        openIndexedDBHandlerComp,
        readAllDataInIdb,
        setLoadedData,
      });
    }
  }, [networkData1Received, openIndexedDBHandlerComp, readAllDataInIdb]);

  const fetchGeneralUserDataHandler = () => {
    fetchGeneralUserData({
      sendRequest,
      auth,
      setNetworkDataReceived: setNetworkData1Received,
      setLoadedData,
      fetchId,
    });
  };

  const welcomeModalCloseHandler = () => {
    localStorage.setItem("first-access", JSON.stringify({ firstAcess: false }));
    setFirstAcess(false);
  };

  const welcomeModalConfirmHandler = () => {
    localStorage.setItem("first-access", JSON.stringify({ firstAcess: false }));
    setFirstAcess(false);
    history.push("/tutoriais");
  };

  if (!loadedData || !settingsLoaded) {
    return (
      <Fragment>
        <ModalError error={error} onClear={clearError} />
        <LoadingSpinnerFullScreenGraph />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      {/* <ModalWelcome
        open={firstAcess}
        closeHandler={welcomeModalCloseHandler}
        confirmHandler={welcomeModalConfirmHandler}
        myTitle="BEM-VINDO AO AZUME"
        imgURL={`${process.env.PUBLIC_URL}/bem-vindo.webp`}
        imgAlt="Bem-Vindo ao Azume"
        imgTitle="Bem-Vindo ao Azume"
        mySubtitle="LEIA ESTA MENSAGEM!"
        myContent="Antes de desfrutar de todos os benefícios do seu novo aplicativo é FORTEMENTE RECOMENDADO que assista aos nossos vídeo-tutoriais. Deseja ser redirecionado para nossa página de tutoriais e liberar o verdadeiro poder do Azume?"
        closeButton
        confirmButtonText="SIM, BORA APRENDER TUDO!"
        closeButtonText="NÃO, JÁ SEI USAR O APP..."
        addToHomescreenEvent={addToHomescreenEvent}
        setAddToHomescreenEvent={setAddToHomescreenEvent}
      /> */}
      <ModalNotification auth={auth} />
      {/* <ModalPrompt
        title="Instale o Azume"
        imgOneUrl={`${process.env.PUBLIC_URL}/azume-iOS-adicionar-a-tela-inicial.webp`}
        imgOneAlt="Instale o Azume no Seu iPhone ou iPad"
        imgTwoUrl={`${process.env.PUBLIC_URL}/azume-safari-botao-compartilhar.webp`}
        imgTwoAlt="Safari Botão Compartilhar"
        text={
          <span>
            Então, selecione a opção <b>"Adicionar à Tela de Início"</b>
          </span>
        }
        open={iosPrompt}
        closeHandler={() => {
          setIosPrompt(false);
          localStorage.setItem(
            "iosInstallPrompt",
            JSON.stringify({ prompt: false, promptDate: dateFormat(0) })
          );
        }}
      /> */}
      {/* {auth.statusOk && !needUpdate && !needCriticalUpdate && (
        <WarningBrowserBar />
      )} */}
      {auth.statusOk && needCriticalUpdate && (
        <WarningBar
          text={
            <span>
              <u>Atualização Importante</u>! <b>FECHE E ABRA</b> o Azume (todas
              abas) até que essa mensagem DESAPAREÇA.
            </span>
          }
          clickableText="DETALHES DA ATUALIZAÇÃO"
          clickAction={() => {
            history.push("/atualizacoes");
          }}
          altColor
        />
      )}
      {auth.statusOk && needUpdate && !needCriticalUpdate && (
        <WarningBar
          text={
            <span>
              <u>Atualização Disponível</u>! <b>FECHE E ABRA</b> o Azume (todas
              abas) até que essa mensagem DESAPAREÇA.
            </span>
          }
          clickableText="DETALHES DA ATUALIZAÇÃO"
          clickAction={() => {
            history.push("/atualizacoes");
          }}
          blueColor
        />
      )}
      {auth.isLoggedIn && !auth.statusOk && (
        <WarningBar
          text="A sua situação de pagamento encontra-se irregular. Devido a isto, foram removidas as funcionalidades do aplicativo até que você possa"
          clickableText="REGULARIZAR A SITUAÇÃO"
          clickAction={() => {
            history.push("/financeiro");
          }}
        />
      )}
      {loadedContent && auth.type !== "VENDOR" && (
        <HomeCarousel carouselData={loadedContent.homeCarousel} auth={auth} />
      )}
      {loadedData && settingsLoaded && (
        <div className={classes.root}>
          {/* <ModalEnableNotification /> */}
          <div className={classes.inner}>
            {auth.type === "MANAGER" && auth.vendors?.length > 0 && (
              <div style={{ marginBottom: "2rem", marginTop: "-2rem" }}>
                <FilterFetchType
                  auth={auth}
                  isLoading={isLoading}
                  fetchId={fetchId}
                  setFetchId={setFetchId}
                  fetchDataHandler={fetchGeneralUserDataHandler}
                  noMarginBottom
                />
              </div>
            )}
            <HomeUserSection0CardsData
              loadedData={loadedData}
              auth={auth}
              addToHomescreenEvent={addToHomescreenEvent}
              setAddToHomescreenEvent={setAddToHomescreenEvent}
            />
            <HomeUserSection1GraphPeriodic loadedData={loadedData} />
          </div>
        </div>
      )}
    </Fragment>
  );
}
