import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { Legend } from "@devexpress/dx-react-chart-material-ui";

const legendStyles = createStyles({
  root: {
    display: "flex",
    margin: "auto",
    flexDirection: "column",
    "& span": {
      fontSize: "0.8rem",
    },
  },
});

const legendRootBase = (props: WithStyles<typeof legendStyles>) => {
  const { classes, ...restProps } = props;
  return (
    <Legend.Root
      {...restProps}
      children={restProps["children"]}
      className={classes.root}
    />
  );
};

export const Root = (withStyles(legendStyles, { name: "LegendRoot" })(
  legendRootBase
) as unknown) as React.ComponentType<any>;

const legendLabelStyles = createStyles({
  label: {
    whiteSpace: "nowrap",
  },
});

const legendLabelBase = (props: WithStyles<typeof legendLabelStyles>) => {
  const { classes, ...restProps } = props;
  return (
    <Legend.Label
      className={classes.label}
      text={restProps["text"]}
      {...restProps}
    />
  );
};

export const Label = (withStyles(legendLabelStyles, { name: "LegendLabel" })(
  legendLabelBase
) as unknown) as React.ComponentType<any>;
