import { Fragment, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Stack } from "@devexpress/dx-react-chart";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";

import { PopoverChangeDates } from "../components/authenticated/PopoverChangeDates";
import { setWeekActivityDataHomeUser } from "../util/dynamicDataProcessing";
import { Root, Label } from "../components/authenticated/HomeGraphMUIStyled";
import { GeneralUserData } from "../../shared/data/types";

interface HomeUserSection1GraphPeriodicProps {
  loadedData: GeneralUserData;
}

export const HomeUserSection1GraphPeriodic = (
  props: HomeUserSection1GraphPeriodicProps
) => {
  const { loadedData } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [pastDatesMultiplierWeekly, setPastDatesMultiplierWeekly] =
    useState<number>(0);

  return (
    <Paper style={{ margin: "50px 0" }}>
      <Fragment>
        <PopoverChangeDates
          id="periodic-revenue-graph-move-popover"
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          disabled={pastDatesMultiplierWeekly === 0}
          maxOffset={3}
          setPastDatesMultiplier={setPastDatesMultiplierWeekly}
        />
        <IconButton
          style={{
            position: "relative",
            left: "10px",
            top: "10px",
          }}
          id="periodic-revenue-graph-move-btn"
          aria-label="mais"
          size="small"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <MoreHorizRoundedIcon
            style={{
              color: "#999",
            }}
          />
        </IconButton>
        <Typography
          style={{
            color: "#999",
            fontSize: "1.2rem",
            textAlign: "center",
            paddingTop: "1rem",
            paddingBottom: "1.5rem",
          }}
          variant="h5"
        >
          Atividade Semanal
        </Typography>
        <Chart
          height={450}
          data={setWeekActivityDataHomeUser(
            loadedData,
            pastDatesMultiplierWeekly
          )}
        >
          <ArgumentAxis />
          <ValueAxis />
          <BarSeries
            name="Vendas Ganhas"
            valueField="closedSales"
            argumentField="date"
            color="#26a69a"
          />
          <BarSeries
            name="Clientes Registrados"
            valueField="registeredCustomers"
            argumentField="date"
            color="#263351"
          />
          <BarSeries
            name="Propostas Geradas"
            valueField="generatedProposals"
            argumentField="date"
            color="#2299ff"
          />
          <BarSeries
            name="Kits Registrados"
            valueField="registeredKits"
            argumentField="date"
            color="#055ca8"
          />
          <Stack />
          <Legend
            position="bottom"
            rootComponent={Root}
            labelComponent={Label}
          />
        </Chart>
      </Fragment>
    </Paper>
  );
};
