import { makeStyles } from "@material-ui/core/styles";

export const HomeStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  inner: {
    margin: "60px auto 80px",
    width: "1100px",
    [theme.breakpoints.down("lg")]: {
      width: "920px",
    },
    [theme.breakpoints.down("md")]: {
      width: "630px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "500px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "310px",
    },
  },
  resposiveGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "0 20px",
    marginBottom: "35px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridGap: "35px 0",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridGap: "35px 0",
    },
  },
  resposiveFlex: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "35px",
    "& .card-item": {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      "& .card-item": {
        width: "100%",
      },
    },
  },
  textArea: {
    marginLeft: "auto",
    width: "230px",
    textAlign: "right",
  },
  subtitle: {
    fontSize: "0.75rem",
    textAlign: "right",
    padding: "0 0.5rem",
    color: "#999",
  },
  info: {
    fontSize: "1.1rem",
    textAlign: "right",
    padding: "0 0.5rem",
    color: "#3C4858",
  },
  footer: {
    borderTop: "1px solid #eee;",
    marginTop: "20px",
  },
  footerLink: {
    fontSize: "0.9rem",
    textAlign: "left",
    color: "#999",
    paddingTop: "0.85rem",
    "&:hover": {
      color: "#2b99ff",
    },
    "&:focus": {
      color: "#2b99ff",
    },
  },
  footerButtonFilter: {
    position: "absolute",
    right: "15px",
    bottom: "10px",
  },
  graphBtn: {
    position: "relative",
    left: "10px",
    top: "10px",
  },
}));
