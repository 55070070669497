import React from "react";

import { MobileNavLinks } from "./MobileNavLinks";
import { BeforeInstallPromptEvent } from "../../../App";
import "./SideMenuStyles.css";

interface SideMenuProps {
  addToHomescreenEvent: BeforeInstallPromptEvent;
  setAddToHomescreenEvent: React.Dispatch<
    React.SetStateAction<BeforeInstallPromptEvent>
  >;
  slug: string;
}

export const SideMenu = (props: SideMenuProps) => {
  const { addToHomescreenEvent, setAddToHomescreenEvent, slug } = props;

  return (
    <nav className="side-menu">
      <MobileNavLinks
        clickCloser={() => {}}
        sideMenu
        addToHomescreenEvent={addToHomescreenEvent}
        setAddToHomescreenEvent={setAddToHomescreenEvent}
        slug={slug}
      />
    </nav>
  );
};
