import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { Card } from "../../shared/components/UIElements/Card";
import { Avatar } from "../../shared/components/UIElements/Avatar";
import { addToHomescreenPrompt } from "../../shared/util/addToHomescreenPrompt";
import { BeforeInstallPromptEvent } from "../../App";
import { GeneralUserData, AuthContextProps } from "../../shared/data/types";

import { HomeStyles } from "../pages/authenticated/HomeStyles";

interface HomeUserSection0CardsDataProps {
  loadedData: GeneralUserData;
  auth: AuthContextProps;
  addToHomescreenEvent: BeforeInstallPromptEvent;
  setAddToHomescreenEvent: React.Dispatch<
    React.SetStateAction<BeforeInstallPromptEvent>
  >;
}

export const HomeUserSection0CardsData = (
  props: HomeUserSection0CardsDataProps
) => {
  const { loadedData, auth, addToHomescreenEvent, setAddToHomescreenEvent } =
    props;
  const classes = HomeStyles();

  return (
    <Fragment>
      <div className={classes.resposiveFlex}>
        <Card cardType="infoCard">
          <NavLink to={`/conta/info/${auth.userId}`}>
            <Avatar
              logo
              background="linear-gradient(60deg, #f5f5f5, #ffffff)"
              userLogo={loadedData.user.logo}
            />
          </NavLink>
          <div className={classes.textArea}>
            <Typography className={classes.subtitle} variant="body1">
              EMPRESA
            </Typography>
            <Typography className={classes.info} variant="body1">
              {loadedData.user?.name?.length > 16
                ? loadedData.user?.name?.substring(0, 16) + "..."
                : loadedData.user?.name}
              {!loadedData.user?.name && "Nome"}
            </Typography>
          </div>
          <div className={classes.footer}>
            <Typography className={classes.footerLink} variant="body1">
              <NavLink to={`/conta/info/${auth.userId}`}>
                <Button
                  style={{ color: "#999" }}
                  onClick={() => {
                    addToHomescreenPrompt(
                      addToHomescreenEvent,
                      setAddToHomescreenEvent
                    );
                  }}
                >
                  Dados da Empresa
                </Button>
              </NavLink>
            </Typography>
          </div>
        </Card>
      </div>
      <div className={classes.resposiveGrid}>
        <Card cardType="infoCard">
          <NavLink to={`/vendas/funil/${auth.userId}`}>
            <Avatar
              budget
              background="linear-gradient(60deg, #00897b, #26a69a)"
            />
          </NavLink>
          <Typography className={classes.subtitle} variant="body1">
            VENDAS GANHAS
          </Typography>
          <Typography className={classes.info} variant="body1">
            {loadedData.totalClosedSales !== 1
              ? `${loadedData.totalClosedSales} Vendas`
              : "1 Venda"}
          </Typography>
          <div className={classes.footer}>
            <Typography className={classes.footerLink} variant="body1">
              <NavLink
                to={`/vendas/funil/${auth.userId}`}
                onClick={() => {
                  addToHomescreenPrompt(
                    addToHomescreenEvent,
                    setAddToHomescreenEvent
                  );
                }}
              >
                <Button style={{ color: "#999" }}>FUNIL DE VENDAS</Button>
              </NavLink>
            </Typography>
          </div>
        </Card>

        <Card cardType="infoCard">
          <NavLink to={`/clientes/lista/${auth.userId}`}>
            <Avatar
              customer
              background="linear-gradient(60deg, #263351, #394b74)"
            />
          </NavLink>
          <Typography className={classes.subtitle} variant="body1">
            CLIENTES
          </Typography>
          <Typography className={classes.info} variant="body1">
            {loadedData.totalCustomers !== 1
              ? `${loadedData.totalCustomers} Clientes`
              : "1 Cliente"}
          </Typography>
          <div className={classes.footer}>
            <Typography className={classes.footerLink} variant="body1">
              <NavLink to={`/clientes/lista/${auth.userId}`}>
                <Button
                  style={{ color: "#999" }}
                  onClick={() => {
                    addToHomescreenPrompt(
                      addToHomescreenEvent,
                      setAddToHomescreenEvent
                    );
                  }}
                >
                  Lista de Clientes
                </Button>
              </NavLink>
            </Typography>
          </div>
        </Card>
      </div>
      <div className={classes.resposiveGrid}>
        <Card cardType="infoCard">
          <NavLink to={`/propostas/lista/${auth.userId}`}>
            <Avatar
              proposal
              background="linear-gradient(60deg, #263351, #394b74)"
            />
          </NavLink>
          <Typography className={classes.subtitle} variant="body1">
            PROPOSTAS
          </Typography>
          <Typography className={classes.info} variant="body1">
            {loadedData.totalProposals !== 1
              ? `${loadedData.totalProposals} Propostas`
              : "1 Proposta"}
          </Typography>
          <div className={classes.footer}>
            <Typography className={classes.footerLink} variant="body1">
              <NavLink
                to={`/propostas/lista/${auth.userId}`}
                onClick={() => {
                  addToHomescreenPrompt(
                    addToHomescreenEvent,
                    setAddToHomescreenEvent
                  );
                }}
              >
                <Button style={{ color: "#999" }}>Lista de Propostas</Button>
              </NavLink>
            </Typography>
          </div>
        </Card>
        <Card cardType="infoCard">
          <NavLink to={`/kits/lista/${auth.userId}`}>
            <Avatar kit background="linear-gradient(60deg, #263351, #394b74)" />
          </NavLink>
          <Typography className={classes.subtitle} variant="body1">
            KITS
          </Typography>
          <Typography className={classes.info} variant="body1">
            {loadedData.totalKits !== 1
              ? `${loadedData.totalKits} Kits`
              : "1 Kit"}
          </Typography>
          <div className={classes.footer}>
            <Typography className={classes.footerLink} variant="body1">
              <NavLink
                to={`/kits/lista/${auth.userId}`}
                onClick={() => {
                  addToHomescreenPrompt(
                    addToHomescreenEvent,
                    setAddToHomescreenEvent
                  );
                }}
              >
                <Button style={{ color: "#999" }}>Lista de Kits</Button>
              </NavLink>
            </Typography>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};
