import { useCallback, useReducer } from "react";

import { Inputs, FormHookDispState } from "../data/types";

interface formHookDispAction {
  type: string;
  value?: string | boolean | File | string[];
  isValid?: boolean;
  inputId?: string;
  label?: string;
  forceError?: boolean;
  reset?: boolean;
  reinitialize?: boolean;
  invalidInputsIds?: string[];
  inputs?: Inputs;
  formIsValid?: boolean;
}

const formReducer = (state: FormHookDispState, action: formHookDispAction) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      let formIsValid = true;
      for (const inputId in state.inputs) {
        if (!state.inputs[inputId]) {
          continue;
        }
        if (inputId === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[inputId].isValid;
        }
      }
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: {
            value: action.value,
            isValid: action.isValid,
            label: action.label,
            forceError: false,
          },
        },
        isValid: formIsValid,
        reset: false,
        reinitialize: false,
      };

    case "SET_DATA":
      return {
        inputs: action.inputs,
        isValid: action.formIsValid,
        reset: action.reset,
        reinitialize: action.reinitialize,
      };

    case "SET_INVALID_INPUTS":
      for (let i = 0; i < action.invalidInputsIds.length; i++) {
        state.inputs[action.invalidInputsIds[i]].forceError = true;
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};

export const useForm = (
  initialInputs: Inputs,
  intialFormValidity: boolean = false,
  initialReset: boolean = false,
  initialReinitialize: boolean = false
) => {
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: initialInputs,
    isValid: intialFormValidity,
    reset: initialReset,
    reinitialize: initialReinitialize,
  });

  const inputHandler = useCallback(
    (
      id: string,
      value: string | boolean | File | string[],
      isValid: boolean,
      label: string
    ) => {
      dispatch({
        type: "INPUT_CHANGE",
        value: value,
        isValid: isValid,
        label: label,
        inputId: id,
      });
    },
    []
  );

  const setFormData = useCallback(
    (
      inputData: Inputs,
      formValidity: boolean,
      resetFLag: boolean = false,
      reinitializeFlag: boolean = false
    ) => {
      dispatch({
        type: "SET_DATA",
        inputs: inputData,
        formIsValid: formValidity,
        reset: resetFLag,
        reinitialize: reinitializeFlag,
      });
    },
    []
  );

  const reportInvalid = useCallback((inputs: Inputs) => {
    const inputIds: string[] = [];
    const invalidInputsIds: string[] = [];
    const invalidInputsLabels: string[] = [];
    for (let input in inputs) {
      inputIds.push(input);
    }
    for (let i = 0; i < inputIds.length; i++) {
      if (!inputs[inputIds[i]].isValid) {
        invalidInputsIds.push(inputIds[i]);
        invalidInputsLabels.push(inputs[inputIds[i]].label);
      }
    }

    dispatch({
      type: "SET_INVALID_INPUTS",
      invalidInputsIds: invalidInputsIds,
    });

    return invalidInputsLabels;
  }, []);

  return { formState, inputHandler, setFormData, reportInvalid };
};
